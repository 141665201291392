var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dictionary && !_vm.error
    ? _c(
        "tabs",
        { attrs: { "list-class": "btn-group btn-group-toggle mb-5" } },
        [
          _c(
            "tab",
            { attrs: { title: _vm.dictionary.truckPageEntry_general_model } },
            [
              _c("TruckSelectorList", {
                attrs: {
                  nodeid: _vm.nodeid,
                  language: _vm.language,
                  dictionary: _vm.dictionary,
                  showprices: _vm.showprices,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { title: _vm.dictionary.truckPageEntry_application } },
            [
              _c("TruckSelectorApplication", {
                attrs: { nodeid: _vm.nodeid, language: _vm.language },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }