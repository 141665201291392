
    import { CabRepository } from "repositories/CabRepository";
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { CabResponseModel } from "../models/CabResponseModel";
    import { DictionaryModel } from "../models/DictionaryModel";

    @Component
    export default class CompareCabs extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public pdflink!: string;

        public error: boolean = false;
        public cabModels: CabResponseModel[] = [];
        public cabModelList: string[] = [];
        public dictionary: DictionaryModel = new DictionaryModel();

        public firstSelectedCab: CabResponseModel | null = null;
        public secondSelectedCab: CabResponseModel | null = null;
        public thirdSelectedCab: CabResponseModel | null = null;

        public async mounted() {
            const cabRepo = new CabRepository();
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckCab", this.language);
                this.cabModels = await cabRepo.get(this.nodeid, this.language);

                this.cabModelList = [...new Set(this.cabModels.map((x) => x.name))];
                this.preSet();
            } catch (err) {
                this.error = true;
            }
        }
        public numberWithCommas = (x: number) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        public preSet() {
            if (this.cabModels.length > 0) {
                this.firstSelectedCab = this.cabModels[0];
            }
            if (this.cabModels.length > 1) {
                this.secondSelectedCab = this.cabModels[1];
            }
            if (this.cabModels.length > 2) {
                this.thirdSelectedCab = this.cabModels[2];
            }
        }
    }


