
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { NewTrucksModel } from "../models/NewTrucksModel";
    import { TruckModel } from "../models/TruckModel";
    import { TruckRepository } from "../repositories/TruckRepository";
    import TruckSelectorListItem from "./TruckSelectorListItem.vue";

    @Component({
        components: {
            TruckSelectorListItem,
        },
    })
    export default class TruckSelectorList extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public dictionary!: DictionaryModel;
        @Prop() public showprices!: boolean;

        public trucks: NewTrucksModel[] = [];
        public allTrucks: TruckModel[] = [];
        public error: boolean = false;
        public dictionaryItems: DictionaryModel = new DictionaryModel();
        public widthClass(count: number) {          
            return 'col-lg-' + (count*2);
        }
        public async mounted() {
            const truckRepo = new TruckRepository();
            const dictionaryRepo = new DictionaryRepository();
            this.dictionaryItems = this.dictionary;
            try {
                this.trucks = await truckRepo.get(this.nodeid, this.language);
                this.trucks.map(cat => cat.truckViewModels.map(truck => this.allTrucks.push(truck)));
                this.allTrucks.sort((a, b) => a.sortOrder - b.sortOrder)
                if (!this.dictionaryItems) {
                    this.dictionaryItems = await dictionaryRepo.get("truckPageEntry", this.language);
                }



                this.error = false;
            } catch (err) {
                this.error = true;
            }
        }
    }
