var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "desktop" }, [
      _c(
        "div",
        { staticClass: "compare-models-container-row content-area row mb-5" },
        [
          _c("div", { staticClass: "compare-models-container col-lg-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("h3", { staticClass: "compare-models-title" }, [
                  _vm._v(
                    _vm._s(_vm.dictionary.truckModel_general_compareModels)
                  ),
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "choosen-models-table table" }, [
                  _c("tbody", [
                    _c(
                      "tr",
                      [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_general_models) +
                              ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.truckModelList, function (truck, i) {
                          return _c("td", { key: i }, [_vm._v(_vm._s(truck))])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-5 text-right" }, [
                _vm.pdflink
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary blue btn-md",
                        attrs: { href: _vm.pdflink, target: "_blank" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary.truckModel_general_downloadOverview
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12 mb-4" }, [
                _c(
                  "table",
                  { staticClass: "compare-models-table table table-hover" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "table-title" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary
                                  .truckModel_general_chooseToCompare
                              ) + "   "
                            ),
                            _c("svg", { staticClass: "fas fa-arrow-right" }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedExecution,
                                  expression: "firstSelectedExecution",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.firstSelectedExecution = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    _vm.firstFilteredModel = ""
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckModel_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.truckExecutions,
                                function (execution, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: execution } },
                                    [_vm._v(_vm._s(execution))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstFilteredModel,
                                  expression: "firstFilteredModel",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.firstFilteredModel = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckModel_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.filterTrucksByExecution(
                                  _vm.firstSelectedExecution
                                ),
                                function (truck, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: truck } },
                                    [_vm._v(_vm._s(truck.name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("th", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedExecution,
                                      expression: "secondSelectedExecution",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.secondSelectedExecution = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.secondFilteredModel = ""
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.truckExecutions,
                                    function (execution, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: execution },
                                        },
                                        [_vm._v(_vm._s(execution))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondFilteredModel,
                                      expression: "secondFilteredModel",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.secondFilteredModel = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterTrucksByExecution(
                                      _vm.secondSelectedExecution
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("th", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedExecution,
                                      expression: "thirdSelectedExecution",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.thirdSelectedExecution = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.thirdFilteredModel = ""
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.truckExecutions,
                                    function (execution, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: execution },
                                        },
                                        [_vm._v(_vm._s(execution))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdFilteredModel,
                                      expression: "thirdFilteredModel",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.thirdFilteredModel = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterTrucksByExecution(
                                      _vm.thirdSelectedExecution
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }),
                        _vm._v(" "),
                        _c("td", [
                          _vm.firstFilteredModel
                            ? _c("img", {
                                attrs: {
                                  src: _vm.firstFilteredModel.image,
                                  alt: _vm.firstFilteredModel.text,
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _vm.secondFilteredModel
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.secondFilteredModel.image,
                                      alt: _vm.secondFilteredModel.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _vm.thirdFilteredModel
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.thirdFilteredModel.image,
                                      alt: _vm.thirdFilteredModel.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_grossVehicleWeight)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstFilteredModel.grossVehicleWeightMin
                            ) + " "
                          ),
                          _vm.firstFilteredModel.grossVehicleWeightMax !=
                            null &&
                          _vm.firstFilteredModel.grossVehicleWeightMax != 0
                            ? _c("span", [
                                _vm._v(
                                  "- " +
                                    _vm._s(
                                      _vm.firstFilteredModel
                                        .grossVehicleWeightMax
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondFilteredModel.grossVehicleWeightMin
                                ) + " "
                              ),
                              _vm.secondFilteredModel.grossVehicleWeightMax !=
                                null &&
                              _vm.secondFilteredModel.grossVehicleWeightMax != 0
                                ? _c("span", [
                                    _vm._v(
                                      "- " +
                                        _vm._s(
                                          _vm.secondFilteredModel
                                            .grossVehicleWeightMax
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdFilteredModel.grossVehicleWeightMin
                                ) + " "
                              ),
                              _vm.thirdFilteredModel.grossVehicleWeightMax !=
                                null &&
                              _vm.thirdFilteredModel.grossVehicleWeightMax != 0
                                ? _c("span", [
                                    _vm._v(
                                      "- " +
                                        _vm._s(
                                          _vm.thirdFilteredModel
                                            .grossVehicleWeightMax
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_wheelBase)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.firstFilteredModel.wheelBaseMin != null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.numberWithCommas(
                                      _vm.firstFilteredModel.wheelBaseMin
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.firstFilteredModel.wheelBaseMax != null &&
                          _vm.firstFilteredModel.wheelBaseMax != 0
                            ? _c("span", [
                                _vm._v(
                                  "- " +
                                    _vm._s(
                                      _vm.numberWithCommas(
                                        _vm.firstFilteredModel.wheelBaseMax
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _vm.secondFilteredModel.wheelBaseMin != null
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(
                                          _vm.secondFilteredModel.wheelBaseMin
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.secondFilteredModel.wheelBaseMax != null &&
                              _vm.secondFilteredModel.wheelBaseMax != 0
                                ? _c("span", [
                                    _vm._v(
                                      "- " +
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.secondFilteredModel.wheelBaseMax
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _vm.thirdFilteredModel.wheelBaseMin != null
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(
                                          _vm.thirdFilteredModel.wheelBaseMin
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.thirdFilteredModel.wheelBaseMax != null &&
                              _vm.thirdFilteredModel.wheelBaseMax != 0
                                ? _c("span", [
                                    _vm._v(
                                      "- " +
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.thirdFilteredModel.wheelBaseMax
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_execution)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.firstFilteredModel.execution)),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _vm._v(_vm._s(_vm.secondFilteredModel.execution)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _vm._v(_vm._s(_vm.thirdFilteredModel.execution)),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_cabs)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.cabResponseModels,
                            function (cab, i) {
                              return _c("p", { key: i }, [
                                _vm._v(_vm._s(cab.name)),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.cabResponseModels,
                                function (cab, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(cab.name)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.cabResponseModels,
                                function (cab, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(cab.name)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_axleConfiguration)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.firstFilteredModel.axleConfiguration)
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondFilteredModel.axleConfiguration
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(_vm.thirdFilteredModel.axleConfiguration)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_suspension)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.firstFilteredModel.suspension)),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(_vm.secondFilteredModel.suspension)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _vm._v(_vm._s(_vm.thirdFilteredModel.suspension)),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_engineType)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.engineResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(_vm._s(engine.engineType)),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.engineType)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.engineType)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_displacement)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.engineResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.numberWithCommas(engine.displacement)
                                  )
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(
                                          engine.displacement
                                        )
                                      )
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(
                                          engine.displacement
                                        )
                                      )
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_kiloWatt)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.engineResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(_vm._s(engine.kiloWatt)),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.kiloWatt)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.kiloWatt)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_horsepower)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.engineResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(_vm._s(engine.horsepower)),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.horsepower)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.horsepower)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckModel_maxTorque)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.engineResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(
                                  _vm._s(_vm.numberWithCommas(engine.maxTorque))
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(engine.maxTorque)
                                      )
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.numberWithCommas(engine.maxTorque)
                                      )
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_emissionClass)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.engineResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(_vm._s(engine.emissionClass)),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.emissionClass)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.engineResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.emissionClass)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_gearboxesSpeeds)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(
                            _vm.firstFilteredModel.gearboxResponseModels,
                            function (engine, i) {
                              return _c("p", { key: i }, [
                                _vm._v(_vm._s(engine.forwardGears)),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.secondFilteredModel.gearboxResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.forwardGears)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c(
                              "td",
                              _vm._l(
                                _vm.thirdFilteredModel.gearboxResponseModels,
                                function (engine, i) {
                                  return _c("p", { key: i }, [
                                    _vm._v(_vm._s(engine.forwardGears)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckModel_axleLoadsWeights)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1 ? _c("td") : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2 ? _c("td") : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.truckModel_firstFrontAxleLoad
                              ) + ","
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.truckModel_secondFrontAxleLoad
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm.firstFilteredModel.firstFrontAxleLoad != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.numberWithCommas(
                                        _vm.firstFilteredModel
                                          .firstFrontAxleLoad
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm.firstFilteredModel.secondFrontAxleLoad != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.numberWithCommas(
                                        _vm.firstFilteredModel
                                          .secondFrontAxleLoad
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _c("p", [
                                _vm.secondFilteredModel.firstFrontAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.secondFilteredModel
                                              .firstFrontAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm.secondFilteredModel.secondFrontAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.secondFilteredModel
                                              .secondFrontAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _c("p", [
                                _vm.thirdFilteredModel.firstFrontAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.thirdFilteredModel
                                              .firstFrontAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm.thirdFilteredModel.secondFrontAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.thirdFilteredModel
                                              .secondFrontAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.truckModel_firstRearAxleLoad
                              ) + ","
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.truckModel_secondRearAxleLoad
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [
                            _vm.firstFilteredModel.firstRearAxleLoad != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.numberWithCommas(
                                        _vm.firstFilteredModel.firstRearAxleLoad
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm.firstFilteredModel.secondRearAxleLoad != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.numberWithCommas(
                                        _vm.firstFilteredModel
                                          .secondRearAxleLoad
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.truckModelList.length > 1
                          ? _c("td", [
                              _c("p", [
                                _vm.secondFilteredModel.firstRearAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.secondFilteredModel
                                              .firstRearAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm.secondFilteredModel.secondRearAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.secondFilteredModel
                                              .secondRearAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.truckModelList.length > 2
                          ? _c("td", [
                              _c("p", [
                                _vm.thirdFilteredModel.firstRearAxleLoad != null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.thirdFilteredModel
                                              .firstRearAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm.thirdFilteredModel.secondRearAxleLoad !=
                                null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.thirdFilteredModel
                                              .secondRearAxleLoad
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.dictionary.truckModel_technically_permitted)
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mobile" }, [
      _c(
        "div",
        { staticClass: "compare-models-container-row content-area row mb-5" },
        [
          _c("div", { staticClass: "compare-models-container col-lg-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("h3", { staticClass: "compare-models-title" }, [
                  _vm._v(
                    _vm._s(_vm.dictionary.truckModel_general_compareModels)
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-5 text-right" }, [
                _vm.pdflink
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary blue btn-md",
                        attrs: { href: _vm.pdflink, target: "_blank" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary.truckModel_general_downloadOverview
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "carousel slide",
                    attrs: {
                      id: "carouselModelsIndicators",
                      "data-ride": "carousel",
                      "data-interval": "false",
                    },
                  },
                  [
                    _c("ol", { staticClass: "carousel-indicators" }, [
                      _c("li", {
                        staticClass: "active",
                        attrs: {
                          "data-target": "#carouselModelsIndicators",
                          "data-slide-to": "0",
                        },
                      }),
                      _vm._v(" "),
                      _vm.truckModelList.length > 1
                        ? _c("li", {
                            attrs: {
                              "data-target": "#carouselModelsIndicators",
                              "data-slide-to": "1",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.truckModelList.length > 2
                        ? _c("li", {
                            attrs: {
                              "data-target": "#carouselModelsIndicators",
                              "data-slide-to": "2",
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "carousel-inner" }, [
                      _c("div", { staticClass: "carousel-item active" }, [
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedExecution,
                                  expression: "firstSelectedExecution",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.firstSelectedExecution = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    _vm.firstFilteredModel = ""
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckModel_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.truckExecutions,
                                function (execution, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: execution } },
                                    [_vm._v(_vm._s(execution))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstFilteredModel,
                                  expression: "firstFilteredModel",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.firstFilteredModel = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckModel_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.filterTrucksByExecution(
                                  _vm.firstSelectedExecution
                                ),
                                function (truck, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: truck } },
                                    [_vm._v(_vm._s(truck.name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-wrapper" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckModel_grossVehicleWeight
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstFilteredModel.grossVehicleWeightMin
                                  ) + " "
                                ),
                                _vm.firstFilteredModel.grossVehicleWeightMax !=
                                  null &&
                                _vm.firstFilteredModel.grossVehicleWeightMax !=
                                  0
                                  ? _c("span", [
                                      _vm._v(
                                        "- " +
                                          _vm._s(
                                            _vm.firstFilteredModel
                                              .grossVehicleWeightMax
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_wheelBase)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm.firstFilteredModel.wheelBaseMin != null
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            _vm.firstFilteredModel.wheelBaseMin
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.firstFilteredModel.wheelBaseMax != null &&
                                _vm.firstFilteredModel.wheelBaseMax != 0
                                  ? _c("span", [
                                      _vm._v(
                                        "- " +
                                          _vm._s(
                                            _vm.numberWithCommas(
                                              _vm.firstFilteredModel
                                                .wheelBaseMax
                                            )
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_execution)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.firstFilteredModel.execution)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(_vm._s(_vm.dictionary.truckModel_cabs)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.cabResponseModels,
                                  function (cab, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(_vm._s(cab.name)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckModel_axleConfiguration
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstFilteredModel.axleConfiguration
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_suspension)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.firstFilteredModel.suspension)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_engineType)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.engineResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(_vm._s(engine.engineType)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_displacement)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.engineResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(
                                            engine.displacement
                                          )
                                        )
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_kiloWatt)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.engineResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(_vm._s(engine.kiloWatt)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_horsepower)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.engineResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(_vm._s(engine.horsepower)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckModel_maxTorque)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.engineResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberWithCommas(engine.maxTorque)
                                        )
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckModel_emissionClass
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.engineResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(_vm._s(engine.emissionClass)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckModel_gearboxesSpeeds
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.firstFilteredModel.gearboxResponseModels,
                                  function (engine, i) {
                                    return _c("p", { key: i }, [
                                      _vm._v(_vm._s(engine.forwardGears)),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckModel_axleLoadsWeights
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td"),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary
                                        .truckModel_firstFrontAxleLoad
                                    ) + ","
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary
                                        .truckModel_secondFrontAxleLoad
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("p", [
                                  _vm.firstFilteredModel.firstFrontAxleLoad !=
                                  null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.numberWithCommas(
                                              _vm.firstFilteredModel
                                                .firstFrontAxleLoad
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm.firstFilteredModel.secondFrontAxleLoad !=
                                  null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.numberWithCommas(
                                              _vm.firstFilteredModel
                                                .secondFrontAxleLoad
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary
                                        .truckModel_firstRearAxleLoad
                                    ) + ","
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary
                                        .truckModel_secondRearAxleLoad
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("p", [
                                  _vm.firstFilteredModel.firstRearAxleLoad !=
                                  null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.numberWithCommas(
                                              _vm.firstFilteredModel
                                                .firstRearAxleLoad
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm.firstFilteredModel.secondRearAxleLoad !=
                                  null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.numberWithCommas(
                                              _vm.firstFilteredModel
                                                .secondRearAxleLoad
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.truckModelList.length > 1
                        ? _c("div", { staticClass: "carousel-item" }, [
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedExecution,
                                      expression: "secondSelectedExecution",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.secondSelectedExecution = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.secondFilteredModel = ""
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.truckExecutions,
                                    function (execution, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: execution },
                                        },
                                        [_vm._v(_vm._s(execution))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondFilteredModel,
                                      expression: "secondFilteredModel",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.secondFilteredModel = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterTrucksByExecution(
                                      _vm.secondSelectedExecution
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "table-wrapper" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_grossVehicleWeight
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondFilteredModel
                                          .grossVehicleWeightMin
                                      ) + " "
                                    ),
                                    _vm.secondFilteredModel
                                      .grossVehicleWeightMax != null &&
                                    _vm.secondFilteredModel
                                      .grossVehicleWeightMax != 0
                                      ? _c("span", [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.secondFilteredModel
                                                  .grossVehicleWeightMax
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_wheelBase
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm.secondFilteredModel.wheelBaseMin != null
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                _vm.secondFilteredModel
                                                  .wheelBaseMin
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.secondFilteredModel.wheelBaseMax !=
                                      null &&
                                    _vm.secondFilteredModel.wheelBaseMax != 0
                                      ? _c("span", [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.secondFilteredModel
                                                    .wheelBaseMax
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_execution
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.secondFilteredModel.execution)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.truckModel_cabs)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel.cabResponseModels,
                                      function (cab, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(cab.name)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_axleConfiguration
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondFilteredModel
                                          .axleConfiguration
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_suspension
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.secondFilteredModel.suspension)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_engineType
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.engineType)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_displacement
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                engine.displacement
                                              )
                                            )
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.truckModel_kiloWatt)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.kiloWatt)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_horsepower
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.horsepower)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_maxTorque
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                engine.maxTorque
                                              )
                                            )
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_emissionClass
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.emissionClass)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_gearboxesSpeeds
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.secondFilteredModel
                                        .gearboxResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.forwardGears)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_axleLoadsWeights
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td"),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_firstFrontAxleLoad
                                        ) + ","
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_secondFrontAxleLoad
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("p", [
                                      _vm.secondFilteredModel
                                        .firstFrontAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.secondFilteredModel
                                                    .firstFrontAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm.secondFilteredModel
                                        .secondFrontAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.secondFilteredModel
                                                    .secondFrontAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_firstRearAxleLoad
                                        ) + ","
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_secondRearAxleLoad
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("p", [
                                      _vm.secondFilteredModel
                                        .firstRearAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.secondFilteredModel
                                                    .firstRearAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm.secondFilteredModel
                                        .secondRearAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.secondFilteredModel
                                                    .secondRearAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.truckModelList.length > 2
                        ? _c("div", { staticClass: "carousel-item" }, [
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedExecution,
                                      expression: "thirdSelectedExecution",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.thirdSelectedExecution = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.thirdFilteredModel = ""
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.truckExecutions,
                                    function (execution, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: execution },
                                        },
                                        [_vm._v(_vm._s(execution))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdFilteredModel,
                                      expression: "thirdFilteredModel",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.thirdFilteredModel = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterTrucksByExecution(
                                      _vm.thirdSelectedExecution
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "table-wrapper" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_grossVehicleWeight
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdFilteredModel
                                          .grossVehicleWeightMin
                                      ) + " "
                                    ),
                                    _vm.thirdFilteredModel
                                      .grossVehicleWeightMax != null &&
                                    _vm.thirdFilteredModel
                                      .grossVehicleWeightMax != 0
                                      ? _c("span", [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.thirdFilteredModel
                                                  .grossVehicleWeightMax
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_wheelBase
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm.thirdFilteredModel.wheelBaseMin != null
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                _vm.thirdFilteredModel
                                                  .wheelBaseMin
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.thirdFilteredModel.wheelBaseMax !=
                                      null &&
                                    _vm.thirdFilteredModel.wheelBaseMax != 0
                                      ? _c("span", [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.thirdFilteredModel
                                                    .wheelBaseMax
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_execution
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.thirdFilteredModel.execution)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.truckModel_cabs)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel.cabResponseModels,
                                      function (cab, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(cab.name)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_axleConfiguration
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdFilteredModel.axleConfiguration
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_suspension
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.thirdFilteredModel.suspension)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_engineType
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.engineType)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_displacement
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                engine.displacement
                                              )
                                            )
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(_vm.dictionary.truckModel_kiloWatt)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.kiloWatt)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_horsepower
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.horsepower)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_maxTorque
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                engine.maxTorque
                                              )
                                            )
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckModel_emissionClass
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .engineResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.emissionClass)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_gearboxesSpeeds
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.thirdFilteredModel
                                        .gearboxResponseModels,
                                      function (engine, i) {
                                        return _c("p", { key: i }, [
                                          _vm._v(_vm._s(engine.forwardGears)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckModel_axleLoadsWeights
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td"),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_firstFrontAxleLoad
                                        ) + ","
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_secondFrontAxleLoad
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("p", [
                                      _vm.thirdFilteredModel
                                        .firstFrontAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.thirdFilteredModel
                                                    .firstFrontAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm.thirdFilteredModel
                                        .secondFrontAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.thirdFilteredModel
                                                    .secondFrontAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_firstRearAxleLoad
                                        ) + ","
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckModel_secondRearAxleLoad
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("p", [
                                      _vm.thirdFilteredModel
                                        .firstRearAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.thirdFilteredModel
                                                    .firstRearAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm.thirdFilteredModel
                                        .secondRearAxleLoad != null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  _vm.thirdFilteredModel
                                                    .secondRearAxleLoad
                                                )
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-prev",
        attrs: {
          href: "#carouselModelsIndicators",
          role: "button",
          "data-slide": "prev",
        },
      },
      [
        _c("span", {
          staticClass: "carousel-control-prev-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-next",
        attrs: {
          href: "#carouselModelsIndicators",
          role: "button",
          "data-slide": "next",
        },
      },
      [
        _c("span", {
          staticClass: "carousel-control-next-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }