var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.error
    ? _c("div", [
        _c(
          "div",
          { staticClass: "row text-center truck-selector" },
          _vm._l(_vm.buses, function (category, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "category-container",
                class: _vm.widthClass(category.busViewModels.length),
              },
              [
                _c("div", { staticClass: "category-title" }, [
                  _c("h3", [_vm._v(_vm._s(category.category))]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(category.busViewModels, function (bus, i) {
                    return _c("BusSelectorListItem", {
                      key: i,
                      attrs: { bus: bus, dictionary: _vm.dictionaryItems },
                    })
                  }),
                  1
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }