var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.error
    ? _c("div", { staticClass: "black-tabs-table-container container mb-5" }, [
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _vm.categories
              ? _c(
                  "tabs",
                  {
                    staticClass: "col-lg-12 mb-4",
                    attrs: { "list-class": "nav nav-tabs" },
                  },
                  _vm._l(_vm.categories, function (category, i) {
                    return _c(
                      "tab",
                      { key: i, attrs: { title: category.category } },
                      [
                        category.subCategory
                          ? _c(
                              "tabs",
                              {
                                staticClass: "col-lg-12 mb-4",
                                attrs: { "list-class": "nav nav-tabs" },
                              },
                              _vm._l(
                                category.subCategory,
                                function (subCategory, i) {
                                  return _c(
                                    "tab",
                                    {
                                      key: i,
                                      attrs: { title: subCategory.category },
                                    },
                                    _vm._l(
                                      subCategory.trucks,
                                      function (truck, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "col-lg-3 col-sm-4",
                                          },
                                          [
                                            _c(
                                              "table",
                                              { staticClass: "table table-sm" },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { colspan: "2" },
                                                      },
                                                      [
                                                        truck.image
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: truck.image,
                                                                alt: truck.text,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("h3", [
                                                          _vm._v(
                                                            _vm._s(truck.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                ]),
                                                _vm._v(" "),
                                                _c("tbody", [
                                                  truck.gvw &&
                                                  truck.gvw.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_gvw
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(truck.gvw)
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.payload &&
                                                  truck.payload.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_payload
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.payloadLabel
                                                              ) +
                                                                "  " +
                                                                _vm._s(
                                                                  truck.payload
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.gvc &&
                                                  truck.gvc.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_gvc
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.gcwLabel
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  truck.gvc
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.drumVolume &&
                                                  truck.drumVolume.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_drumVolume
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.drumVolume
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.tipperVolume &&
                                                  truck.tipperVolume.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_tipperVolume
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.tipperVolume
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.axleConfiguration &&
                                                  truck.axleConfiguration
                                                    .length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_axleConfig
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.axleConfiguration
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.wheelbase &&
                                                  truck.wheelbase.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_wheelbase
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.wheelbase
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  truck.horsepower &&
                                                  truck.horsepower.length > 0
                                                    ? _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.dictionary
                                                                .truckApplication_horsepower
                                                            ) + ":"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                truck.horsepower
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            truck.linkUrl &&
                                            truck.linkUrl.length > 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary btn-sm",
                                                    attrs: {
                                                      href: truck.linkUrl,
                                                    },
                                                  },
                                                  [
                                                    _c("svg", {
                                                      staticClass:
                                                        "fas fa-chevron-right",
                                                    }),
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(
                                                          _vm.dictionary
                                                            .truckApplication_model_link
                                                        ) +
                                                        " " +
                                                        _vm._s(truck.name) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }