
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import Tab from "./Tab.vue";

    @Component({
        components: {
            Tab,
        },
    })
    export default class Tabs extends Vue {

        private tabs: any[] = [];
        private activeTabHash: string = '';
        private activeTabIndex: number = 0;
        private lastActiveTabHash: string = '';
        @Prop() public listClass!: string;
        @Prop() public filterItems!: string[];
        @Prop() public filterFunction!: Function;
        @Prop() public filterActive!: string;

        public async created() {
            this.tabs = this.$children as Tab[];
        }

        public async updated() {
            if (!this.activeTabHash) {
                this.selectFirstTab();
            }
        }

        public onFilterTabClick(filterItem: string) {
            this.selectFirstTab();
            this.filterFunction(filterItem);
        }

        public selectFirstTab() {
            if (this.tabs.length) {
                this.selectTab(this.tabs[0].hash);
            }
        }

        private findTab(hash: string) {
            return this.tabs.find(tab => tab.hash === hash);
        }

        private selectTab(selectedTabHash: string, event: any = null) {
            const selectedTab = this.findTab(selectedTabHash);
            if (!selectedTab) {
                return;
            }
            if (this.lastActiveTabHash === selectedTab.hash) {
                this.$emit('clicked', { tab: selectedTab });
                return;
            }
            this.tabs.forEach(tab => {
                tab.active = (tab.hash === selectedTab.hash);
            });
            this.$emit('changed', { tab: selectedTab });
            this.activeTabHash = selectedTab.hash;
            this.activeTabIndex = this.getTabIndex(selectedTabHash);

            this.lastActiveTabHash = this.activeTabHash = selectedTab.hash;
        }

        private getTabIndex(hash: string){
            const tab = this.findTab(hash);
            return this.tabs.indexOf(tab);
        }
    }
