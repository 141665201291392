
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    //import Tab from "./Tab.vue";
    //import Tabs from "./Tabs.vue";
    import BusSelectorList from "./BusSelectorList.vue";

    @Component({
        components: {
            //Tab,
            //Tabs,
            BusSelectorList,
        },
    })
    export default class BusSelector extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;

        public error: boolean = false;
        public dictionary: DictionaryModel | null = null;

        public async mounted() {
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("busPageEntry", this.language);
            } catch (err) {
                this.error = true;
            }
        }
    }
