import axios, { AxiosInstance } from "axios";
import { IRead } from "../interfaces/IRead";

export abstract class BaseRepository<T> implements IRead<T> {
    public readonly api: AxiosInstance;

    constructor() {
        const baseURL = "/umbraco/api";
        this.api = axios.create({
            baseURL,
        });
    }

    public get(...params: any): Promise<T[] | T> {
        throw new Error("Method not implemented");
    }
}
