var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("BusSelectorList", {
    attrs: {
      nodeid: _vm.nodeid,
      language: _vm.language,
      dictionary: _vm.dictionary,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }