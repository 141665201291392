
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { NewBusesModel } from "../models/NewBusesModel";
    import { BusRepository } from "../repositories/BusRepository";
    import BusSelectorListItem from "./BusSelectorListItem.vue";

    @Component({
        components: {
            BusSelectorListItem,
        },
    })
    export default class BusSelectorList extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public dictionary!: DictionaryModel;

        public buses: NewBusesModel[] = [];
        public error: boolean = false;
        public dictionaryItems: DictionaryModel = new DictionaryModel();
        public widthClass(count: number) {          
            return 'col-lg-' + (count*2);
        }
        public async mounted() {
            const busRepo = new BusRepository();
            const dictionaryRepo = new DictionaryRepository();
            this.dictionaryItems = this.dictionary;
            try {
                this.buses = await busRepo.get(this.nodeid, this.language);
                if (!this.dictionaryItems) {
                    this.dictionaryItems = await dictionaryRepo.get("buskPageEntry", this.language);
                }
                this.error = false;
            } catch (err) {
                this.error = true;
            }
        }
    }
