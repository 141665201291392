
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    //import { ApplicationResponseModel } from "../models/ApplicationResponseModel";
    import { DictionaryModel } from "../models/DictionaryModel";
    //import { BusRepository } from "../repositories/BusRepository";

    @Component
    export default class BusListItem extends Vue {
        @Prop() public bus!: any;
        @Prop() public dictionary!: DictionaryModel;
    }
