var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "tabs-component" }, [
    _vm.filterItems
      ? _c(
          "ul",
          { class: _vm.listClass, attrs: { role: "tablist" } },
          _vm._l(_vm.filterItems, function (filterItem, index) {
            return _c("li", { key: index }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  class: { active: _vm.filterActive === filterItem },
                  on: {
                    click: function ($event) {
                      return _vm.onFilterTabClick(filterItem)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(filterItem) + "\n            "
                  ),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { class: _vm.listClass, attrs: { role: "tablist" } },
      _vm._l(_vm.tabs, function (tabItem, tabIndex) {
        return _c(
          "li",
          {
            key: tabIndex,
            staticClass: "tabs-component-tab",
            attrs: { role: "presentation" },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                class: { active: tabItem.active },
                attrs: { for: tabItem.hash, role: "presentation" },
                on: {
                  click: function ($event) {
                    return _vm.selectTab(tabItem.hash, $event)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(tabItem.title) +
                    "\n            "
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tabs-component-panels" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }