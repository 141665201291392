var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "desktop" }, [
      _c(
        "div",
        { staticClass: "compare-models-container-row content-area row mb-5" },
        [
          _c("div", { staticClass: "compare-models-container col-lg-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("h3", { staticClass: "compare-models-title" }, [
                  _vm._v(
                    _vm._s(_vm.dictionary.truckEngine_general_compareEngines)
                  ),
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "choosen-models-table table" }, [
                  _c(
                    "tbody",
                    _vm._l(_vm.engineEmissions, function (emission, i) {
                      return _c(
                        "tr",
                        { key: i },
                        [
                          _c("td", { staticClass: "row-title" }, [
                            _vm._v(_vm._s(emission) + ":"),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.engineModels.filter(
                              (x) => x.emissionClass === emission
                            ),
                            function (engine, i) {
                              return _c("td", { key: i }, [
                                _vm._v(_vm._s(engine.name)),
                              ])
                            }
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-5 text-right" }, [
                _vm.pdflink
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary blue btn-md",
                        attrs: { href: _vm.pdflink, target: "_blank" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary.truckEngine_general_downloadOverview
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12 mb-4" }, [
                _c(
                  "table",
                  { staticClass: "compare-models-table table table-hover" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "table-title" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary
                                  .truckEngine_general_chooseToCompare
                              ) + "   "
                            ),
                            _c("svg", { staticClass: "fas fa-arrow-right" }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedEmission,
                                  expression: "firstSelectedEmission",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.firstSelectedEmission = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    _vm.firstFilteredEngine = null
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckEngine_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.engineEmissions,
                                function (emission, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: emission } },
                                    [_vm._v(_vm._s(emission))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedEngine,
                                  expression: "firstSelectedEngine",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.firstSelectedEngine = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckEngine_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.filterEngineByEmission(
                                  _vm.firstSelectedEmission
                                ),
                                function (truck, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: truck } },
                                    [_vm._v(_vm._s(truck.name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("th", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedEmission,
                                      expression: "secondSelectedEmission",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.secondSelectedEmission = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.secondSelectedEngine = null
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.engineEmissions,
                                    function (emission, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: emission },
                                        },
                                        [_vm._v(_vm._s(emission))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedEngine,
                                      expression: "secondSelectedEngine",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.secondSelectedEngine = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterEngineByEmission(
                                      _vm.secondSelectedEmission
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("th", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedEmission,
                                      expression: "thirdSelectedEmission",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.thirdSelectedEmission = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.thirdSelectedEngine = null
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.engineEmissions,
                                    function (emission, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: emission },
                                        },
                                        [_vm._v(_vm._s(emission))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedEngine,
                                      expression: "thirdSelectedEngine",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.thirdSelectedEngine = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterEngineByEmission(
                                      _vm.thirdSelectedEmission
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }),
                        _vm._v(" "),
                        _vm.firstSelectedEngine
                          ? _c("td", [
                              _vm.firstSelectedEngine.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.firstSelectedEngine.image,
                                      alt: _vm.firstSelectedEngine.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.secondSelectedEngine
                          ? _c("td", [
                              _vm.secondSelectedEngine.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.secondSelectedEngine.image,
                                      alt: _vm.secondSelectedEngine.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.thirdSelectedEngine
                          ? _c("td", [
                              _vm.thirdSelectedEngine.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.thirdSelectedEngine.image,
                                      alt: _vm.thirdSelectedEngine.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckEngine_engineDesignation)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.firstSelectedEngine.engineDesignation
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.secondSelectedEngine.engineDesignation
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.thirdSelectedEngine.engineDesignation
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckEngine_engineType)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.firstSelectedEngine.engineType
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.secondSelectedEngine.engineType
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.thirdSelectedEngine.engineType
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckEngine_displacement)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.numberWithCommas(
                                  _vm.firstSelectedEngine.displacement
                                )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.secondSelectedEngine.displacement
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.thirdSelectedEngine.displacement
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckEngine_kiloWatt)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.numberWithCommas(
                                  _vm.firstSelectedEngine.kiloWatt
                                )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.secondSelectedEngine.kiloWatt
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.thirdSelectedEngine.kiloWatt
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckEngine_horsepower)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.numberWithCommas(
                                  _vm.firstSelectedEngine.horsepower
                                )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.secondSelectedEngine.horsepower
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.thirdSelectedEngine.horsepower
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckEngine_maxTorque)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.numberWithCommas(
                                  _vm.firstSelectedEngine.maxTorque
                                )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.secondSelectedEngine.maxTorque
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.numberWithCommas(
                                      _vm.thirdSelectedEngine.maxTorque
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckEngine_emissionClass)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedEngine &&
                                _vm.firstSelectedEngine.emissionClass
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.engineModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedEngine &&
                                    _vm.secondSelectedEngine.emissionClass
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.engineModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedEngine &&
                                    _vm.thirdSelectedEngine.emissionClass
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mobile" }, [
      _c(
        "div",
        { staticClass: "compare-models-container-row content-area row mb-5" },
        [
          _c("div", { staticClass: "compare-models-container col-lg-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("h3", { staticClass: "compare-models-title" }, [
                  _vm._v(
                    _vm._s(_vm.dictionary.truckEngine_general_compareEngines)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "carousel slide",
                    attrs: {
                      id: "carouselExampleIndicators",
                      "data-ride": "carousel",
                      "data-interval": "false",
                    },
                  },
                  [
                    _c("ol", { staticClass: "carousel-indicators" }, [
                      _c("li", {
                        staticClass: "active",
                        attrs: {
                          "data-target": "#carouselExampleIndicators",
                          "data-slide-to": "0",
                        },
                      }),
                      _vm._v(" "),
                      _vm.engineModels.length > 1
                        ? _c("li", {
                            attrs: {
                              "data-target": "#carouselExampleIndicators",
                              "data-slide-to": "1",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.engineModels.length > 2
                        ? _c("li", {
                            attrs: {
                              "data-target": "#carouselExampleIndicators",
                              "data-slide-to": "2",
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "carousel-inner" }, [
                      _c("div", { staticClass: "carousel-item active" }, [
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedEmission,
                                  expression: "firstSelectedEmission",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.firstSelectedEmission = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    _vm.firstFilteredEngine = null
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckEngine_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.engineEmissions,
                                function (emission, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: emission } },
                                    [_vm._v(_vm._s(emission))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedEngine,
                                  expression: "firstSelectedEngine",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.firstSelectedEngine = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckEngine_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.filterEngineByEmission(
                                  _vm.firstSelectedEmission
                                ),
                                function (truck, i) {
                                  return _c(
                                    "option",
                                    { key: i, domProps: { value: truck } },
                                    [_vm._v(_vm._s(truck.name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-wrapper" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckEngine_engineDesignation
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.firstSelectedEngine.engineDesignation
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckEngine_engineType)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.firstSelectedEngine.engineType
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckEngine_displacement
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.numberWithCommas(
                                        _vm.firstSelectedEngine.displacement
                                      )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckEngine_kiloWatt)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.numberWithCommas(
                                        _vm.firstSelectedEngine.kiloWatt
                                      )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckEngine_horsepower)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.numberWithCommas(
                                        _vm.firstSelectedEngine.horsepower
                                      )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckEngine_maxTorque)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.numberWithCommas(
                                        _vm.firstSelectedEngine.maxTorque
                                      )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dictionary.truckEngine_emissionClass
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedEngine &&
                                      _vm.firstSelectedEngine.emissionClass
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.engineModels.length > 1
                        ? _c("div", { staticClass: "carousel-item" }, [
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedEmission,
                                      expression: "secondSelectedEmission",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.secondSelectedEmission = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.secondSelectedEngine = null
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.engineEmissions,
                                    function (emission, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: emission },
                                        },
                                        [_vm._v(_vm._s(emission))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedEngine,
                                      expression: "secondSelectedEngine",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.secondSelectedEngine = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterEngineByEmission(
                                      _vm.secondSelectedEmission
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "table-wrapper" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckEngine_engineDesignation
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.secondSelectedEngine
                                            .engineDesignation
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_engineType
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.secondSelectedEngine.engineType
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_displacement
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.secondSelectedEngine
                                              .displacement
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_kiloWatt
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.secondSelectedEngine.kiloWatt
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_horsepower
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.secondSelectedEngine.horsepower
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_maxTorque
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.secondSelectedEngine.maxTorque
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_emissionClass
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedEngine &&
                                          _vm.secondSelectedEngine.emissionClass
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.engineModels.length > 2
                        ? _c("div", { staticClass: "carousel-item" }, [
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedEmission,
                                      expression: "thirdSelectedEmission",
                                    },
                                  ],
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.thirdSelectedEmission = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        _vm.thirdSelectedEngine = null
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.engineEmissions,
                                    function (emission, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: emission },
                                        },
                                        [_vm._v(_vm._s(emission))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedEngine,
                                      expression: "thirdSelectedEngine",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.thirdSelectedEngine = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckEngine_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filterEngineByEmission(
                                      _vm.thirdSelectedEmission
                                    ),
                                    function (truck, i) {
                                      return _c(
                                        "option",
                                        { key: i, domProps: { value: truck } },
                                        [_vm._v(_vm._s(truck.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "table-wrapper" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary
                                          .truckEngine_engineDesignation
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.thirdSelectedEngine
                                            .engineDesignation
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_engineType
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.thirdSelectedEngine.engineType
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_displacement
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.thirdSelectedEngine.displacement
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_kiloWatt
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.thirdSelectedEngine.kiloWatt
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_horsepower
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.thirdSelectedEngine.horsepower
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_maxTorque
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.numberWithCommas(
                                            _vm.thirdSelectedEngine.maxTorque
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckEngine_emissionClass
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedEngine &&
                                          _vm.thirdSelectedEngine.emissionClass
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-prev",
        attrs: {
          href: "#carouselExampleIndicators",
          role: "button",
          "data-slide": "prev",
        },
      },
      [
        _c("span", {
          staticClass: "carousel-control-prev-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-next",
        attrs: {
          href: "#carouselExampleIndicators",
          role: "button",
          "data-slide": "next",
        },
      },
      [
        _c("span", {
          staticClass: "carousel-control-next-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }