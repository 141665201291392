import { MediaLibraryItem } from "../models/MediaLibraryItem";
import { MediaLibraryModel } from "../models/MediaLibraryModel";
import { BaseRepository } from "./base/BaseRepository";

export class MediaLibraryRepository extends BaseRepository<MediaLibraryModel> {

    public async get(id: number, type: string = "", category: string = "", page: number = 0, pageSize: number = 300): Promise<MediaLibraryModel> {
        try {
            const res = await this.api.get(`/MediaLibrary/GetMediaByTypeAndCategory?id=${id}&type=${type}&category=${category}&page=${page}&pageSize=${pageSize}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }

    public getZIP(mediaItems: MediaLibraryItem[]) {
        var mediaIds = [];
        for (let media of mediaItems) {
            mediaIds.push(media.id);
        }
        this.api.post('/MediaLibrary/PostCreateZIPArchive', mediaItems,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/zip'
                }
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                let fileName = response.headers["content-disposition"].split("filename=")[1];
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
    }




}
