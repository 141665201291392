import { CabResponseModel } from "../models/CabResponseModel";
import { BaseRepository } from "./base/BaseRepository";

export class CabRepository extends BaseRepository<CabResponseModel> {
    public async get(id: number, langauge: string): Promise<CabResponseModel[]> {
        try {
            const res = await this.api.get(`/TruckIndustriesApplications/GetCabResponseModels?id=${id}&language=${langauge}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
}
