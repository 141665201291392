
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import { TruckModelRepository } from "repositories/TruckModelRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { TruckModelResponseModel } from "../models/TruckModelResponseModel";

    @Component
    export default class CompareModels extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public pdflink!: string;

        public firstSelectedTruck?: TruckModelResponseModel;
        public firstSelectedExecution: string = "";
        public firstFilteredModel: any = "";
        public secondSelectedTruck?: TruckModelResponseModel;
        public secondSelectedExecution: string = "";
        public secondFilteredModel: any = "";
        public thirdSelectedTruck?: TruckModelResponseModel;
        public thirdSelectedExecution: string = "";
        public thirdFilteredModel: any = "";
        public truckExecutions: string[] = [];
        public truckModelList: string[] = [];

        public truckModels: TruckModelResponseModel[] = [];
        public error: boolean = false;
        public dictionary: DictionaryModel = new DictionaryModel();

        public async mounted() {
            const truckRepo = new TruckModelRepository();
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckModel", this.language);
                this.truckModels = await truckRepo.get(this.nodeid, this.language);
                this.truckExecutions = [...new Set(this.truckModels.map((x) => x.execution))];
                this.truckModelList = [...new Set(this.truckModels.map((x) => x.name))];
                this.preSet();
            } catch (err) {
                this.error = true;
            }
        }

        public preSet() {
            this.firstSelectedExecution = this.truckExecutions[0];
            let filteredTrucksModels = this.filterTrucksByExecution(this.firstSelectedExecution);
            this.firstFilteredModel = filteredTrucksModels[0];

            if (filteredTrucksModels.length > 1) {
                this.secondSelectedExecution = this.truckExecutions[0];
                this.secondFilteredModel = filteredTrucksModels[1];
            }
            if (filteredTrucksModels.length > 2) {
                this.thirdSelectedExecution = this.truckExecutions[0];
                this.thirdFilteredModel = filteredTrucksModels[2];
            }
        }

        public filterTrucksByExecution(executionFilter: string) {
            return [...new Set(this.truckModels.filter((x) => x.execution === executionFilter))];
        }
        public numberWithCommas = (x: any) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
