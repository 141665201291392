
    import Tab from "@components/Tab.vue";
    import Tabs from "@components/Tabs.vue";
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { ApplicationRangeViewModel } from "../models/ApplicationRangeViewModel";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { ApplicationRangeViewRepository } from "../repositories/ApplicationRangeViewRepository";

    @Component({
        components: {
            tab: Tab,
            tabs: Tabs,
        },
    })
    export default class TruckTypesByApplications extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;

        public error: boolean = false;
        public truckTypes: ApplicationRangeViewModel[] = [];
        public truckTypesList: string[] = [];
        public executionCategoriesList: string[] = [];
        public executionCategory: string = "";
        public dictionary: DictionaryModel = new DictionaryModel();

        public async mounted() {
            const truckTypesRepo = new ApplicationRangeViewRepository();
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckApplication", this.language);

                this.truckTypes = await truckTypesRepo.get(this.nodeid, this.language);
                this.executionCategoriesList = [...new Set(this.truckTypes.map((item) => item.executionCategory))];
                if (!this.executionCategory && this.executionCategoriesList) {
                    this.executionCategory = this.executionCategoriesList[0];
                    this.filterTypesByExecutionCategory(this.executionCategory);
                }
            } catch (err) {
                this.error = true;
            }
        }

        public filterTypesByExecutionCategory(executionCategory: string) {
            this.executionCategory = executionCategory;
            const filteredByExecutionCategory = [...new Set(this.truckTypes.filter((type) => type.executionCategory === executionCategory))];
            this.truckTypesList = [...new Set(filteredByExecutionCategory.map((item) => item.execution))];
            return filteredByExecutionCategory;
        }

        public filterTypesByExecution(execution: string) {
            return [...new Set(this.truckTypes.filter((type) => type.execution === execution))];
        }
    }
