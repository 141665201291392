
    import Tab from "@components/Tab.vue";
    import Tabs from "@components/Tabs.vue";
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { ApplicationViewModel } from "../models/ApplicationViewModel";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { ApplicationViewRepository } from "../repositories/ApplicationViewRepository";

    @Component({
        components: {
            tab: Tab,
            tabs: Tabs,
        },
    })
    export default class TruckTypes extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;

        public error: boolean = false;
        public categories: ApplicationViewModel[] = [];
        public dictionary: DictionaryModel = new DictionaryModel();

        public async mounted() {
            const truckTypesRepo = new ApplicationViewRepository();
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckApplication", this.language);

                this.categories = await truckTypesRepo.get(this.nodeid, this.language);
            } catch (err) {
                this.error = true;
            }
        }
    }
