var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.error
    ? _c("div", { staticClass: "black-tabs-table-container container mb-5" }, [
        _c(
          "div",
          { staticClass: "double-tabs row justify-content-center" },
          [
            _vm.truckTypesList
              ? _c(
                  "tabs",
                  {
                    staticClass: "col-lg-10 mb-4",
                    attrs: {
                      "list-class": "nav nav-tabs",
                      "filter-items": _vm.executionCategoriesList,
                      "filter-function": _vm.filterTypesByExecutionCategory,
                      "filter-active": _vm.executionCategory,
                    },
                  },
                  _vm._l(_vm.truckTypesList, function (type, i) {
                    return _c(
                      "tab",
                      { key: i, attrs: { title: type } },
                      _vm._l(
                        _vm.filterTypesByExecution(type),
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "col-lg-3 col-sm-4" },
                            [
                              _c("table", { staticClass: "table table-sm" }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { attrs: { colspan: "2" } }, [
                                      item.imageSrc
                                        ? _c("img", {
                                            attrs: {
                                              src: item.imageSrc,
                                              alt: item.text,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("h3", [_vm._v(_vm._s(item.name))]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckApplication_tipperVolume
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.tipperVolume)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckApplication_wheelbase
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.wheelbase)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary.truckApplication_gvw
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.gvw)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckApplication_axleConfig
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.axleConfig)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckApplication_engineType
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.engineType)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckApplication_horsepower
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.horsepower)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckApplication_gbSpeeds
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.gbSpeeds)),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }