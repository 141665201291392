
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { ApplicationResponseModel } from "../models/ApplicationResponseModel";
    import { ApplicationRepository } from "../repositories/ApplicationRepository";
    import TruckSelectorApplicationItem from "./TruckSelectorApplicationItem.vue";

    @Component({
        components: {
            TruckSelectorApplicationItem,
        },
    })
    export default class TruckSelectorApplication extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;

        public applications: ApplicationResponseModel[] = [];
        public error: boolean = false;

        public async mounted() {
            const applicationRepo = new ApplicationRepository();

            try {
                this.applications = await applicationRepo.get(this.nodeid, this.language);
                this.error = false;
            } catch (err) {
                this.error = true;
            }
        }
    }
