
    import Vue from "vue";
    import {Component, Prop} from "vue-property-decorator";

    @Component
    export default class Tab extends Vue {
        private computedId: string = '';
        @Prop({required: true}) public title!: string;
        private isActive: boolean = false;

        public header() {
                return this.title;
        }

        get id(): string {
                return this.computedId ? this.computedId : this.title.toLowerCase().replace(/ /g, '-');
        }

        get active() {
            return this.isActive;
        }

        set active(active: boolean) {
            this.isActive = active;
        }

        get hash(): string {
            return '#' + this.id;
        }

    }
