
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { ApplicationResponseModel } from "../models/ApplicationResponseModel";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { TruckRepository } from "../repositories/TruckRepository";

    @Component
    export default class TruckListItem extends Vue {
        @Prop() public truck!: any;
        @Prop() public dictionary!: DictionaryModel;
        @Prop() public showprices!: boolean;
    }
