
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import { EngineRepository } from "repositories/EngineRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { EngineResponseModel } from "../models/EngineResponseModel";

    @Component
    export default class CompareEngines extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public pdflink!: string;

        public error: boolean = false;
        public engineModels: EngineResponseModel[] = [];
        public engineModelList: string[] = [];
        public engineEmissions: string[] = [];
        public dictionary: DictionaryModel = new DictionaryModel();

        public firstSelectedEngine?: EngineResponseModel | null = null;
        public firstSelectedEmission: string = "";
        public secondSelectedEngine: EngineResponseModel | null = null;
        public secondSelectedEmission: string = "";
        public thirdSelectedEngine: EngineResponseModel | null = null;
        public thirdSelectedEmission: string = "";

        public async mounted() {
            const engineRepo = new EngineRepository();
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckEngine", this.language);
                this.engineModels = await engineRepo.get(this.nodeid, this.language);

                this.engineEmissions = [...new Set(this.engineModels.map((x) => x.emissionClass))];
                this.engineModelList = [...new Set(this.engineModels.map((x) => x.name))];
                this.preSet();
            } catch (err) {
                this.error = true;
            }
        }

        public preSet() {
            this.firstSelectedEmission = this.engineEmissions[0];
            let filteredEngines = this.filterEngineByEmission(this.firstSelectedEmission);
            this.firstSelectedEngine = filteredEngines[0];

            if (filteredEngines.length > 1) {
                this.secondSelectedEmission = this.engineEmissions[0];
                this.secondSelectedEngine = filteredEngines[1];
            }
            if (filteredEngines.length > 2) {
                this.thirdSelectedEmission = this.engineEmissions[0];
                this.thirdSelectedEngine = filteredEngines[2];
            }
        }

        public filterEngineByEmission(filterEmission: string) {
            return [...new Set(this.engineModels.filter((x) => x.emissionClass === filterEmission))];
        }
        public numberWithCommas = (x: number) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
