var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { class: _vm.listClass },
    _vm._l(_vm.tabs, function (tab, index) {
      return _c(
        "li",
        { key: index, class: { active: _vm.tabActive === tab } },
        [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.onTabClick(tab, $event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.translatedTabs[index]))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }