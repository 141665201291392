import { EngineResponseModel } from "../models/EngineResponseModel";
import { BaseRepository } from "./base/BaseRepository";

export class EngineRepository extends BaseRepository<EngineResponseModel> {
    public async get(id: number, langauge: string): Promise<EngineResponseModel[]> {
        try {
            const res = await this.api.get(`/TruckIndustriesApplications/GetEngineModels?id=${id}&language=${langauge}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
}
