import { NewBusesModel } from "../models/NewBusesModel";
import { BaseRepository } from "./base/BaseRepository";

export class BusRepository extends BaseRepository<NewBusesModel> {
    public async get(id: number, language: string): Promise<NewBusesModel[]> {
        try {
            const res = await this.api.get(`/TruckIndustriesApplications/GetBuses?id=${id}&language=${language}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
}
