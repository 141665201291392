var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "truck-card col" }, [
    _c("div", { staticClass: "category-title" }, [
      _c("h3", [_vm._v(_vm._s(_vm.truck.truckModels))]),
    ]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.truck.linkUrl } }, [
      _c("div", { staticClass: "card h-100" }, [
        _c("img", {
          staticClass: "card-img-top",
          attrs: {
            src: _vm.truck.imageUrl + "?mode=crop&width=160&height=90",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              _vm._s(_vm.dictionary.truckPageEntry_gvw) +
                ": " +
                _vm._s(_vm.truck.gvw)
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              _vm._s(_vm.dictionary.truckPageEntry_horsepower) +
                ": " +
                _vm._s(_vm.truck.horsepower)
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              _vm._s(_vm.dictionary.truckPageEntry_displacement) +
                ": " +
                _vm._s(_vm.truck.displacement)
            ),
          ]),
          _vm._v(" "),
          _vm.showprices
            ? _c("p", { staticClass: "card-text" }, [
                _vm._v(
                  _vm._s(_vm.dictionary.truckPageEntry_price) +
                    ": " +
                    _vm._s(_vm.truck.price)
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }