

      import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";

     @Component
    export default class MediaDownloads extends Vue {

     public async mounted() {

      }

    }

    