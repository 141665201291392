var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "desktop" }, [
      _c(
        "div",
        { staticClass: "compare-models-container-row content-area row mb-5" },
        [
          _c("div", { staticClass: "compare-models-container col-lg-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("h3", { staticClass: "compare-models-title" }, [
                  _vm._v(_vm._s(_vm.dictionary.truckCab_general_compareCabs)),
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "choosen-models-table table" }, [
                  _c("tbody", [
                    _c(
                      "tr",
                      [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckCab_general_cabs) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.cabModelList, function (cab, i) {
                          return _c("td", { key: i }, [_vm._v(_vm._s(cab))])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-5 text-right" }, [
                _vm.pdflink
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary blue btn-md",
                        attrs: { href: _vm.pdflink, target: "_blank" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.dictionary.truckCab_general_downloadOverview
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12 mb-4" }, [
                _c(
                  "table",
                  { staticClass: "compare-models-table table table-hover" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "table-title" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.dictionary.truckCab_general_chooseToCompare
                              ) + "   "
                            ),
                            _c("svg", { staticClass: "fas fa-arrow-right" }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedCab,
                                  expression: "firstSelectedCab",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.firstSelectedCab = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckCab_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.cabModels, function (cab, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: cab } },
                                  [_vm._v(_vm._s(cab.name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.cabModels.length > 1
                          ? _c("th", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedCab,
                                      expression: "secondSelectedCab",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.secondSelectedCab = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckCab_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.cabModels, function (cab, i) {
                                    return _c(
                                      "option",
                                      { key: i, domProps: { value: cab } },
                                      [_vm._v(_vm._s(cab.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cabModels.length > 2
                          ? _c("th", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedCab,
                                      expression: "thirdSelectedCab",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.thirdSelectedCab = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckCab_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.cabModels, function (cab, i) {
                                    return _c(
                                      "option",
                                      { key: i, domProps: { value: cab } },
                                      [_vm._v(_vm._s(cab.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }),
                        _vm._v(" "),
                        _vm.firstSelectedCab
                          ? _c("td", [
                              _vm.firstSelectedCab.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.firstSelectedCab.image,
                                      alt: _vm.firstSelectedCab.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.secondSelectedCab
                          ? _c("td", [
                              _vm.secondSelectedCab.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.secondSelectedCab.image,
                                      alt: _vm.secondSelectedCab.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.thirdSelectedCab
                          ? _c("td", [
                              _vm.thirdSelectedCab.image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.thirdSelectedCab.image,
                                      alt: _vm.thirdSelectedCab.text,
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(
                            _vm._s(_vm.dictionary.truckCab_exteriorLength)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedCab &&
                                _vm.numberWithCommas(
                                  _vm.firstSelectedCab.exteriorLength
                                )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.cabModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedCab &&
                                    _vm.numberWithCommas(
                                      _vm.secondSelectedCab.exteriorLength
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cabModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedCab &&
                                    _vm.numberWithCommas(
                                      _vm.thirdSelectedCab.exteriorLength
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticClass: "row-title" }, [
                          _vm._v(_vm._s(_vm.dictionary.truckCab_exteriorWidth)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.firstSelectedCab &&
                                _vm.numberWithCommas(
                                  _vm.firstSelectedCab.exteriorWidth
                                )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.cabModels.length > 1
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.secondSelectedCab &&
                                    _vm.numberWithCommas(
                                      _vm.secondSelectedCab.exteriorWidth
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.cabModels.length > 2
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.thirdSelectedCab &&
                                    _vm.numberWithCommas(
                                      _vm.thirdSelectedCab.exteriorWidth
                                    )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.cabModels.length > 0 &&
                      _vm.cabModels[0].interiorWidth.length > 0
                        ? _c("tr", [
                            _c("td", { staticClass: "row-title" }, [
                              _vm._v(
                                _vm._s(_vm.dictionary.truckCab_interiorWidth)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.firstSelectedCab &&
                                    _vm.firstSelectedCab.interiorWidth
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.cabModels.length > 1
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.secondSelectedCab &&
                                        _vm.secondSelectedCab.interiorWidth
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 2
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.thirdSelectedCab &&
                                        _vm.thirdSelectedCab.interiorWidth
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cabModels.length > 0 &&
                      _vm.cabModels[0].interiorHeight.length > 0
                        ? _c("tr", [
                            _c("td", { staticClass: "row-title" }, [
                              _vm._v(
                                _vm._s(_vm.dictionary.truckCab_interiorHeight)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.firstSelectedCab &&
                                    _vm.firstSelectedCab.interiorHeight
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.cabModels.length > 1
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.secondSelectedCab &&
                                        _vm.secondSelectedCab.interiorHeight
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 2
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.thirdSelectedCab &&
                                        _vm.thirdSelectedCab.interiorHeight
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cabModels.length > 0 &&
                      _vm.cabModels[0].interiorWidthX.length > 0
                        ? _c("tr", [
                            _c("td", { staticClass: "row-title" }, [
                              _vm._v(
                                _vm._s(_vm.dictionary.truckCab_interiorWidthX)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.firstSelectedCab &&
                                    _vm.firstSelectedCab.interiorWidthX
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.cabModels.length > 1
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.secondSelectedCab &&
                                        _vm.secondSelectedCab.interiorWidthX
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 2
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.thirdSelectedCab &&
                                        _vm.thirdSelectedCab.interiorWidthX
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cabModels.length > 0 &&
                      _vm.cabModels[0].interiorHeightX.length > 0
                        ? _c("tr", [
                            _c("td", { staticClass: "row-title" }, [
                              _vm._v(
                                _vm._s(_vm.dictionary.truckCab_interiorHeightX)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.firstSelectedCab &&
                                    _vm.firstSelectedCab.interiorHeightX
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.cabModels.length > 1
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.secondSelectedCab &&
                                        _vm.secondSelectedCab.interiorHeightX
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 2
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.thirdSelectedCab &&
                                        _vm.thirdSelectedCab.interiorHeightX
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cabModels.length > 0 &&
                      _vm.cabModels[0].interiorHeightY.length > 0
                        ? _c("tr", [
                            _c("td", { staticClass: "row-title" }, [
                              _vm._v(
                                _vm._s(_vm.dictionary.truckCab_interiorHeightY)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.firstSelectedCab &&
                                    _vm.firstSelectedCab.interiorHeightY
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.cabModels.length > 1
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.secondSelectedCab &&
                                        _vm.secondSelectedCab.interiorHeightY
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 2
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.thirdSelectedCab &&
                                        _vm.thirdSelectedCab.interiorHeightY
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mobile" }, [
      _c(
        "div",
        { staticClass: "compare-models-container-row content-area row mb-5" },
        [
          _c("div", { staticClass: "compare-models-container col-lg-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("h3", { staticClass: "compare-models-title" }, [
                  _vm._v(_vm._s(_vm.dictionary.truckCab_general_compareCabs)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "carousel slide",
                    attrs: {
                      id: "carouselCabsIndicators",
                      "data-ride": "carousel",
                      "data-interval": "false",
                    },
                  },
                  [
                    _c("ol", { staticClass: "carousel-indicators" }, [
                      _c("li", {
                        staticClass: "active",
                        attrs: {
                          "data-target": "#carouselCabsIndicators",
                          "data-slide-to": "0",
                        },
                      }),
                      _vm._v(" "),
                      _vm.cabModels.length > 1
                        ? _c("li", {
                            attrs: {
                              "data-target": "#carouselCabsIndicators",
                              "data-slide-to": "1",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cabModels.length > 2
                        ? _c("li", {
                            attrs: {
                              "data-target": "#carouselCabsIndicators",
                              "data-slide-to": "2",
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "carousel-inner" }, [
                      _c("div", { staticClass: "carousel-item active" }, [
                        _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.firstSelectedCab,
                                  expression: "firstSelectedCab",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.firstSelectedCab = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dictionary.truckCab_general_default
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.cabModels, function (cab, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: cab } },
                                  [_vm._v(_vm._s(cab.name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-wrapper" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckCab_exteriorLength)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedCab &&
                                      _vm.numberWithCommas(
                                        _vm.firstSelectedCab.exteriorLength
                                      )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "row-title" }, [
                                _vm._v(
                                  _vm._s(_vm.dictionary.truckCab_exteriorWidth)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.firstSelectedCab &&
                                      _vm.numberWithCommas(
                                        _vm.firstSelectedCab.exteriorWidth
                                      )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.cabModels.length > 0 &&
                            _vm.cabModels[0].interiorWidth.length > 0
                              ? _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_interiorWidth
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.firstSelectedCab &&
                                          _vm.firstSelectedCab.interiorWidth
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 0 &&
                            _vm.cabModels[0].interiorHeight.length > 0
                              ? _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_interiorHeight
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.firstSelectedCab &&
                                          _vm.firstSelectedCab.interiorHeight
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 0 &&
                            _vm.cabModels[0].interiorWidthX.length > 0
                              ? _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_interiorWidthX
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.firstSelectedCab &&
                                          _vm.firstSelectedCab.interiorWidthX
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 0 &&
                            _vm.cabModels[0].interiorHeightX.length > 0
                              ? _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_interiorHeightX
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.firstSelectedCab &&
                                          _vm.firstSelectedCab.interiorHeightX
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cabModels.length > 0 &&
                            _vm.cabModels[0].interiorHeightY.length > 0
                              ? _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_interiorHeightY
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.firstSelectedCab &&
                                          _vm.firstSelectedCab.interiorHeightY
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.cabModels.length > 1
                        ? _c("div", { staticClass: "carousel-item" }, [
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.secondSelectedCab,
                                      expression: "secondSelectedCab",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.secondSelectedCab = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckCab_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.cabModels, function (cab, i) {
                                    return _c(
                                      "option",
                                      { key: i, domProps: { value: cab } },
                                      [_vm._v(_vm._s(cab.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "table-wrapper" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_exteriorLength
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedCab &&
                                          _vm.numberWithCommas(
                                            _vm.secondSelectedCab.exteriorLength
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_exteriorWidth
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondSelectedCab &&
                                          _vm.numberWithCommas(
                                            _vm.secondSelectedCab.exteriorWidth
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorWidth.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorWidth
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.secondSelectedCab &&
                                              _vm.secondSelectedCab
                                                .interiorWidth
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorHeight.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorHeight
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.secondSelectedCab &&
                                              _vm.secondSelectedCab
                                                .interiorHeight
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorWidthX.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorWidthX
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.secondSelectedCab &&
                                              _vm.secondSelectedCab
                                                .interiorWidthX
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorHeightX.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorHeightX
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.secondSelectedCab &&
                                              _vm.secondSelectedCab
                                                .interiorHeightX
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorHeightY.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorHeightY
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.secondSelectedCab &&
                                              _vm.secondSelectedCab
                                                .interiorHeightY
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cabModels.length > 2
                        ? _c("div", { staticClass: "carousel-item" }, [
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.thirdSelectedCab,
                                      expression: "thirdSelectedCab",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.thirdSelectedCab = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dictionary
                                            .truckCab_general_default
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.cabModels, function (cab, i) {
                                    return _c(
                                      "option",
                                      { key: i, domProps: { value: cab } },
                                      [_vm._v(_vm._s(cab.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "table-wrapper" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_exteriorLength
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedCab &&
                                          _vm.numberWithCommas(
                                            _vm.thirdSelectedCab.exteriorLength
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { staticClass: "row-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dictionary.truckCab_exteriorWidth
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.thirdSelectedCab &&
                                          _vm.numberWithCommas(
                                            _vm.thirdSelectedCab.exteriorWidth
                                          )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorWidth.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorWidth
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.thirdSelectedCab &&
                                              _vm.thirdSelectedCab.interiorWidth
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorHeight.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorHeight
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.thirdSelectedCab &&
                                              _vm.thirdSelectedCab
                                                .interiorHeight
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorWidthX.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorWidthX
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.thirdSelectedCab &&
                                              _vm.thirdSelectedCab
                                                .interiorWidthX
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorHeightX.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorHeightX
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.thirdSelectedCab &&
                                              _vm.thirdSelectedCab
                                                .interiorHeightX
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.cabModels.length > 0 &&
                                _vm.cabModels[0].interiorHeightY.length > 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "row-title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dictionary
                                              .truckCab_interiorHeightY
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.thirdSelectedCab &&
                                              _vm.thirdSelectedCab
                                                .interiorHeightY
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-prev",
        attrs: {
          href: "#carouselCabsIndicators",
          role: "button",
          "data-slide": "prev",
        },
      },
      [
        _c("span", {
          staticClass: "carousel-control-prev-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-next",
        attrs: {
          href: "#carouselCabsIndicators",
          role: "button",
          "data-slide": "next",
        },
      },
      [
        _c("span", {
          staticClass: "carousel-control-next-icon",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }