var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.error
    ? _c("div", [
        _c(
          "div",
          { staticClass: "row text-center truck-selector" },
          _vm._l(_vm.allTrucks, function (truck, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "category-container",
                class: _vm.widthClass(1),
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("TruckSelectorListItem", {
                      attrs: {
                        truck: truck,
                        dictionary: _vm.dictionaryItems,
                        showprices: _vm.showprices,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }