import { TruckModelResponseModel } from "../models/TruckModelResponseModel";
import { BaseRepository } from "./base/BaseRepository";

export class TruckModelRepository extends BaseRepository<TruckModelResponseModel> {
    public async get(id: number, language: string): Promise<TruckModelResponseModel[]> {
        try {
            const res = await this.api.get(`/TruckIndustriesApplications/GetTruckModels?id=${id}&language=${language}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
}
