
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";

    @Component
    export default class MediaLibraryTabs extends Vue {
        @Prop() public listClass!: string;
        @Prop() public tabs!: string[];
        @Prop() public translatedTabs!: string[];
        @Prop() public tabActive!: string;
        @Prop() public handleClick!: Function;

        public onTabClick(tab: string, evt: any) {
            this.handleClick(tab);
            evt.preventDefault();
        }
    }
