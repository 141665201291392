import CompareModels from "@components/Compare.vue";
import CompareCabs from "@components/CompareCabs.vue";
import CompareEngines from "@components/CompareEngines.vue";
import CompareGearboxes from "@components/CompareGearboxes.vue";
import MediaLibrary from "@components/MediaLibrary.vue";
import MediaDownloads from "@components/MediaDownloads.vue";
import TruckSelector from "@components/TruckSelector.vue";
import BusSelector from "@components/BusSelector.vue";
import TruckSelectorList from "@components/TruckSelectorList.vue";
import BusSelectorList from "@components/BusSelectorList.vue";
import TruckTypes from "@components/TruckTypes.vue";
import TruckTypesByApplication from "@components/TruckTypesByApplications.vue";
import Vue from "vue";


const truckSelector: HTMLElement | null = document.getElementById("truckSelector");
if (truckSelector) {
    const truckSelectorApp = new Vue({
        components: {
            "truck-selector": TruckSelector,
        },
        el: truckSelector,
    });
}

const busSelector: HTMLElement | null = document.getElementById("busSelector");
if (busSelector) {
    const busSelectorApp = new Vue({
        components: {
            "bus-selector": BusSelector,
        },
        el: busSelector,
    });
}

const compareModels: HTMLElement | null = document.getElementById("compareModels");
if (compareModels) {
    const compareModelsApp = new Vue({
        components: {
            "compare-models": CompareModels,
        },
        el: compareModels,
    });
}

const compareCabs: HTMLElement | null = document.getElementById("compareCabs");
if (compareCabs) {
    const compareCabsApp = new Vue({
        components: {
            "compare-cabs": CompareCabs,
        },
        el: compareCabs,
    });
}

const compareEngines: HTMLElement | null = document.getElementById("compareEngines");
if (compareEngines) {
    const compareEnginesApp = new Vue({
        components: {
            "compare-engines": CompareEngines,
        },
        el: compareEngines,
    });
}

const compareGearboxes: HTMLElement | null = document.getElementById("compareGearboxes");
if (compareGearboxes) {
    const compareGearboxesApp = new Vue({
        components: {
            "compare-gearboxes": CompareGearboxes,
        },
        el: compareGearboxes,
    });
}

const mediaLibrary: HTMLElement | null = document.getElementById("mediaLibrary");
if (mediaLibrary) {
    const mediaLibraryApp = new Vue({
        components: {
            "media-library": MediaLibrary,
        },
        el: mediaLibrary,
    });
}

const mediaDownloads: HTMLElement | null = document.getElementById("mediaDownloads");
if (mediaDownloads) {
    const mediaDownloadsApp = new Vue({
        components: {
            "media-downloads": MediaDownloads,
        },
        el: mediaDownloads,
    });
}

const truckTypes: HTMLElement | null = document.getElementById("truckTypes");
if (truckTypes) {
    const truckTypesApp = new Vue({
        components: {
            "truck-types": TruckTypes,
        },
        el: truckTypes,
    });
}

const truckTypesByApplication: HTMLElement | null = document.getElementById("truckTypesByApplication");
if (truckTypesByApplication) {
    const truckTypesByApplicationApp = new Vue({
        components: {
            "truck-types-application": TruckTypesByApplication,
        },
        el: truckTypesByApplication,
    });
}

const truckSelectorList: HTMLElement | null = document.getElementById("truckSelectorList");
if (truckSelectorList) {
    const truckSelectorListApp = new Vue({
        components: {
            "truck-selector-list": TruckSelectorList,
        },
        el: truckSelectorList,
    });
}

const busSelectorList: HTMLElement | null = document.getElementById("busSelectorList");
if (busSelectorList) {
    const busSelectorListApp = new Vue({
        components: {
            "bus-selector-list": BusSelectorList,
        },
        el: busSelectorList,
    });
}
