
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import { MediaLibraryRepository } from "repositories/MediaLibraryRepository";
    import Vue from "vue";
    import VueCookies from 'vue-cookies'
    Vue.use(VueCookies)
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { MediaLibraryItem } from "../models/MediaLibraryItem";
    import { MediaLibraryMetaInformations } from "../models/MediaLibraryMetaInformations";
    import { MediaLibraryModel } from "../models/MediaLibraryModel";
    import MediaLibraryTabs from "./MediaLibraryTabs.vue";
    import Tab from "./Tab.vue";
    import Tabs from "./Tabs.vue";
    @Component({
        components: {
            MediaLibraryTabs,
            Tab,
            Tabs,
        },
    })
    export default class MediaLibrary extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public headertext!: string;
        @Prop() public showdownloadmodal!: boolean;
        public mediaLibraryRepo!: MediaLibraryRepository;
        public defaultCategoryFilter: string = "All Categories";
        public defaultTypeFilter: string = "All Media";
        public cookieName: string = "mediadownloaditems";
        public mediaList: MediaLibraryModel = new MediaLibraryModel();
        public mediaListCategories: string[] = [];
        public mediaListTypes: string[] = [];
        public translatedMediaListTypes: string[] = [];
        public translatedMediaListCategories: string[] = [];
        public categoryFilter: string = this.defaultCategoryFilter;
        public typeFilter: string = this.defaultTypeFilter;
        public collectDownloadsBtnLabel: string = "";
        public downloadsBtnLabel: string = "";
        public acceptTermsBtnLabel: string = "";
        public showMoreBtnLabel: string = "";
        public error: boolean = false;
        public dictionary: DictionaryModel = new DictionaryModel();
        public showItems: number = 8;
        public termsaccepted: boolean = false;
        public downloadList: MediaLibraryItem[] = [];
        get pagedMediaItems() {
            return this.mediaList.mediaItems.slice(0, this.showItems);
        }




        public async mounted() {
            this.mediaLibraryRepo = new MediaLibraryRepository();
            const dictionaryRepo = new DictionaryRepository();
            Vue.$cookies.config('7d');
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("mediaCenter", this.language);
                this.mediaList = await this.mediaLibraryRepo.get(this.nodeid);
                //this.defaultTypeFilter = this.dictionary.mediaCenter_allMedia;
                this.defaultTypeFilter = "All Media";
                //this.defaultCategoryFilter = this.dictionary.mediaCenter_allCategories;
                this.defaultCategoryFilter = "All Categories";
                this.collectDownloadsBtnLabel = this.dictionary.mediaCenter_collectedDownloads;
                this.downloadsBtnLabel = this.dictionary.mediaCenter_download;
                this.showMoreBtnLabel = this.dictionary.mediaCenter_showMore;
                this.acceptTermsBtnLabel = this.dictionary.mediaCenter_acceptTerms;

                this.translatedMediaListCategories = [this.dictionary.mediaCenter_allCategories, ...new Set(this.mediaList.mediaItems.map((x) => x.categoryName))];
                this.mediaListCategories = ["All Categories", ...new Set(this.mediaList.mediaItems.map((x) => x.categoryName))];
                this.translatedMediaListTypes = [this.dictionary.mediaCenter_allMedia, ...new Set(this.mediaList.mediaItems.map((x) => x.mediaType))].map((type) => this.dictionary[`mediaCenter_${type.toLowerCase()}s`] ? this.dictionary[`mediaCenter_${type.toLowerCase()}s`] : type);
                this.mediaListTypes = ["All Media", ...new Set(this.mediaList.mediaItems.map((x) => x.mediaType))].map((type) => type);
                this.syncLists();
            } catch (err) {
                this.error = true;
            }
        }
        public async filterCategories(category: string) {
            this.categoryFilter = category;
            this.mediaList = await this.mediaLibraryRepo.get(this.nodeid, this.getFilterType(), this.getFilterCategorie());
        }
        public async filterTypes(type: string) {
            this.typeFilter = type;
            this.mediaList = await this.mediaLibraryRepo.get(this.nodeid, this.getFilterType(), this.getFilterCategorie());
        }

        public collectDownloadItem(media: MediaLibraryItem) {
            this.termsaccepted = false;
            if (Vue.$cookies.isKey(this.cookieName)) {
                var cookieValue = Vue.$cookies.get(this.cookieName).split('_');
                if (cookieValue.indexOf(media.id) == -1) {
                    cookieValue.push(media.id);
                    Vue.$cookies.set(this.cookieName, cookieValue.join('_'));
                }
            } else {
                Vue.$cookies.set(this.cookieName, media.id);
            }
            this.syncLists();
        }
        syncLists() {
            if (Vue.$cookies.isKey(this.cookieName)) {
                var cookieValue = Vue.$cookies.get(this.cookieName).split('_');
                this.downloadList = [];
                for (let id of cookieValue) {
                    for (let media of this.pagedMediaItems) {
                        if (id == media.id) {
                            this.downloadList.push(media);
                        }
                    }
                }
            }
        }
        getZIP() {
            this.mediaLibraryRepo.getZIP(this.downloadList);
            this.downloadList = [];
            $('#feature-listlightbox').hide();
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            Vue.$cookies.remove(this.cookieName);
            this.termsaccepted = false;
        }
        onHidden(e: Event) {
            console.log('the modal was hidden')
        }
        public getFilterCategorie() {
            return this.defaultCategoryFilter === this.categoryFilter ? "" : this.categoryFilter;
        }
        public getFilterType() {
            return this.defaultTypeFilter === this.typeFilter ? "" : this.typeFilter;
        }
        public showMore() {
            this.showItems = this.showItems + 8;
        }
    }
