
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import { GearboxRepository } from "repositories/GearboxRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import { GearboxResponseModel } from "../models/GearboxResponseModel";

    @Component
    export default class CompareGearboxes extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public pdflink!: string;

        public error: boolean = false;
        public gearboxModels: GearboxResponseModel[] = [];
        public gearboxModelList: string[] = [];
        public dictionary: DictionaryModel = new DictionaryModel();

        public firstSelectedGearbox: GearboxResponseModel | null = null;
        public firstSelectedEmission: string = "";
        public secondSelectedGearbox: GearboxResponseModel | null = null;
        public secondSelectedEmission: string = "";
        public thirdSelectedGearbox: GearboxResponseModel | null = null;
        public thirdSelectedEmission: string = "";

        public async mounted() {
            const gearboxRepo = new GearboxRepository();
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckGearbox", this.language);
                this.gearboxModels = await gearboxRepo.get(this.nodeid, this.language);
                this.gearboxModelList = [...new Set(this.gearboxModels.map((x) => x.name))];
                this.preSet();
            } catch (err) {
                this.error = true;
            }
        }
        public preSet() {
            if (this.gearboxModels.length > 0) {
                this.firstSelectedGearbox = this.gearboxModels[0];
            }
            if (this.gearboxModels.length > 1) {
                this.secondSelectedGearbox = this.gearboxModels[1];
            }
            if (this.gearboxModels.length > 2) {
                this.secondSelectedGearbox = this.gearboxModels[2];
            }
        }
    }
