import { DictionaryModel } from "../models/DictionaryModel";
import { BaseRepository } from "./base/BaseRepository";

export class DictionaryRepository extends BaseRepository<DictionaryModel> {
    public async get(area: string, langauge: string): Promise<DictionaryModel> {
        try {
            const res = await this.api.get(`/Dictionary/GetDictionary?area=${area}&language=${langauge}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
}
