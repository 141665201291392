var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "link-tile col-lg-4 mb-5" }, [
    _c("a", { attrs: { href: _vm.application.url } }, [
      _c("div", { staticClass: "tile-image" }, [
        _c("img", {
          attrs: {
            src: _vm.application.imageUrl + "?mode=crop&width=440&height=248",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tile-text" }, [
        _c("h4", [_vm._v(_vm._s(_vm.application.name))]),
        _c("img", { attrs: { src: "/Assets/Images/arrow.png" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }