import { ApplicationRangeViewModel } from "../models/ApplicationRangeViewModel";
import { BaseRepository } from "./base/BaseRepository";

export class ApplicationRangeViewRepository extends BaseRepository<ApplicationRangeViewModel> {
    public async get(id: number, language: string): Promise<ApplicationRangeViewModel[]> {
        try {
            const res = await this.api.get(`/TruckIndustriesApplications/GetApplicationOverviewByModel?id=${id}&language=${language}`);
            if (res && res.status === 200) {
                return res.data;
            }
            throw new Error(res.data);
        } catch (ex) {
            throw new Error(ex);
        }
    }
}