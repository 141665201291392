var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.error
    ? _c(
        "div",
        {
          staticClass: "media-library-container-row row content-area my-5",
          class: { daimlerMediaPage: _vm.showdownloadmodal == "true" },
        },
        [
          _c("div", { staticClass: "media-library-container col-lg-12" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-12" },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.headertext) },
                      }),
                      _vm._v(" "),
                      _c("MediaLibraryTabs", {
                        attrs: {
                          listClass: "nav nav-tabs selection-container",
                          tabs: _vm.mediaListTypes,
                          translatedTabs: _vm.translatedMediaListTypes,
                          tabActive: _vm.typeFilter,
                          handleClick: _vm.filterTypes,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "category-container col-lg-12" },
                    [
                      _c("MediaLibraryTabs", {
                        attrs: {
                          listClass: "nav nav-tabs light",
                          tabs: _vm.mediaListCategories,
                          translatedTabs: _vm.translatedMediaListCategories,
                          tabActive: _vm.categoryFilter,
                          handleClick: _vm.filterCategories,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.showdownloadmodal == "true"
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 text-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          "data-toggle": "modal",
                          href: "#feature-listlightbox",
                          target: "_self",
                        },
                        on: {
                          click: function ($event) {
                            _vm.termsaccepted = false
                          },
                        },
                      },
                      [
                        _c("svg", { staticClass: "fas fa-shopping-cart" }),
                        _vm._v(
                          _vm._s(_vm.collectDownloadsBtnLabel) +
                            " " +
                            _vm._s(_vm.downloadList.length)
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  { staticClass: "row justify-content-center" },
                  [
                    _vm._l(_vm.pagedMediaItems, function (media, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "link-tile col-xl-3 col-lg-4 col-xs-6",
                        },
                        [
                          _c("div", { staticClass: "tile-image" }, [
                            media.mediaType === "File" &&
                            _vm.showdownloadmodal == "false"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "opener",
                                    attrs: {
                                      href: media.downloadUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "feature-image",
                                      attrs: {
                                        src:
                                          media.previewUrl +
                                          "?mode=crop&width=320&height=180",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showdownloadmodal == "true" &&
                            media.downloadable
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "opener",
                                    attrs: {
                                      "data-toggle": "modal",
                                      href: "#feature-dlightbox" + i,
                                      target: "_self",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "feature-image",
                                      attrs: {
                                        src:
                                          media.previewUrl +
                                          "?mode=crop&width=320&height=180",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            media.mediaType === "Image" &&
                            _vm.showdownloadmodal == "false"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "opener",
                                    attrs: {
                                      "data-toggle": "modal",
                                      href: "#feature-lightbox" + i,
                                      target: "_self",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "feature-image",
                                      attrs: {
                                        src:
                                          media.previewUrl +
                                          "?mode=crop&width=320&height=180",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            media.mediaType === "Video" && !media.downloadable
                              ? _c("iframe", {
                                  attrs: {
                                    src: media.previewUrl,
                                    frameborder: "0",
                                    allowfullscreen: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showdownloadmodal == "false"
                              ? _c("div", { staticClass: "opener-btn" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: media.mediaType != "File",
                                          expression:
                                            "media.mediaType != 'File'",
                                        },
                                      ],
                                      staticClass: "opener",
                                      attrs: {
                                        "data-toggle": "modal",
                                        href: "#feature-lightbox" + i,
                                        target: "_self",
                                      },
                                    },
                                    [_c("svg", { staticClass: "fas fa-plus" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: media.mediaType === "File",
                                          expression:
                                            "media.mediaType === 'File'",
                                        },
                                      ],
                                      staticClass: "opener",
                                      attrs: {
                                        href: media.downloadUrl,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("svg", {
                                        staticClass: "fas fa-download",
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showdownloadmodal == "true"
                              ? _c("div", { staticClass: "opener-btn" }, [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: media.downloadable,
                                          expression: "media.downloadable",
                                        },
                                      ],
                                      staticClass: "opener",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.collectDownloadItem(media)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg", {
                                        staticClass: "fas fa-shopping-cart",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "opener",
                                      attrs: {
                                        "data-toggle": "modal",
                                        href: "#feature-dlightbox" + i,
                                        target: "_self",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.termsaccepted = false
                                        },
                                      },
                                    },
                                    [
                                      _c("svg", {
                                        staticClass: "fas fa-download",
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "tile-text" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-8" }, [
                                _c("span", [_vm._v(_vm._s(media.imageText))]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              ref: "vuemodal",
                              refInFor: true,
                              staticClass:
                                "common-modal feature-modal modal fade",
                              attrs: {
                                id: "feature-lightbox" + i,
                                tabindex: "-1",
                                role: "dialog",
                                "aria-labelledby": "feature-lightbox-label",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-dialog",
                                  attrs: { role: "document" },
                                },
                                [
                                  _c("div", { staticClass: "modal-content" }, [
                                    _vm._m(0, true),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "modal-body" }, [
                                      _c("div", { staticClass: "container" }, [
                                        _c("div", { staticClass: "row mb-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-12" },
                                            [
                                              media.mediaType !== "Video"
                                                ? _c("img", {
                                                    staticClass:
                                                      "feature-image",
                                                    attrs: {
                                                      src:
                                                        media.previewUrl +
                                                        "?mode=crop&width=900&height=500",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              media.mediaType === "Video"
                                                ? _c("iframe", {
                                                    attrs: {
                                                      src: media.previewUrl,
                                                      frameborder: "0",
                                                      allowfullscreen: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "common-modal feature-modal modal fade",
                              attrs: {
                                id: "feature-dlightbox" + i,
                                tabindex: "-1",
                                role: "dialog",
                                "aria-labelledby": "feature-lightbox-label",
                                "aria-hidden": "true",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-dialog",
                                  attrs: { role: "document" },
                                },
                                [
                                  _c("div", { staticClass: "modal-content" }, [
                                    _c("div", { staticClass: "modal-header" }, [
                                      _c("h4", [
                                        _vm._v(
                                          _vm._s(_vm.downloadsBtnLabel) +
                                            ": " +
                                            _vm._s(media.imageText)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm._m(1, true),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "modal-body" }, [
                                      _c("div", { staticClass: "container" }, [
                                        _c("div", { staticClass: "row mb-3" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-12" },
                                            [
                                              media.mediaType !== "Video"
                                                ? _c("img", {
                                                    staticClass:
                                                      "feature-image",
                                                    attrs: {
                                                      src:
                                                        media.previewUrl +
                                                        "?mode=crop&width=900&height=500",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              media.mediaType === "Video" &&
                                              media.downloadable
                                                ? _c(
                                                    "video",
                                                    {
                                                      attrs: {
                                                        width: "700",
                                                        height: "500",
                                                        controls: "",
                                                      },
                                                    },
                                                    [
                                                      _c("source", {
                                                        attrs: {
                                                          src: media.downloadUrl,
                                                          type: "video/mp4",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                                                        Your browser does not support the video tag.\n                                                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              media.mediaType === "Video" &&
                                              !media.downloadable
                                                ? _c("iframe", {
                                                    attrs: {
                                                      src: media.previewUrl,
                                                      frameborder: "0",
                                                      allowfullscreen: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row mb-4 conditions",
                                          },
                                          [
                                            media.downloadable
                                              ? _c(
                                                  "div",
                                                  { staticClass: "col-lg-12" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.termsaccepted,
                                                          expression:
                                                            "termsaccepted",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.termsaccepted
                                                        )
                                                          ? _vm._i(
                                                              _vm.termsaccepted,
                                                              null
                                                            ) > -1
                                                          : _vm.termsaccepted,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.termsaccepted,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.termsaccepted =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.termsaccepted =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.termsaccepted =
                                                              $$c
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _vm._m(2, true),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _vm.termsaccepted
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              href: media.downloadUrl,
                                                              target: "_blank",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.termsaccepted = false
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("svg", {
                                                              staticClass:
                                                                "fas fa-download",
                                                            }),
                                                            _vm._v(" Download"),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.termsaccepted
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                          },
                                                          [
                                                            _c("svg", {
                                                              staticClass:
                                                                "fas fa-download",
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.downloadsBtnLabel
                                                                )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "vuemodal",
                        staticClass: "common-modal feature-modal modal fade",
                        attrs: {
                          id: "feature-listlightbox",
                          tabindex: "-1",
                          role: "dialog",
                          "aria-labelledby": "feature-lightbox-label",
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "modal-dialog",
                            attrs: { role: "document" },
                          },
                          [
                            _c("div", { staticClass: "modal-content" }, [
                              _c("div", { staticClass: "modal-header" }, [
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.collectDownloadsBtnLabel)),
                                ]),
                                _vm._v(" "),
                                _vm._m(3),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "modal-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "container" },
                                  [
                                    _vm._l(
                                      _vm.downloadList,
                                      function (media, i) {
                                        return _c(
                                          "div",
                                          { key: i, staticClass: "row mb-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-lg-3" },
                                              [
                                                media.mediaType === "Image"
                                                  ? _c("svg", {
                                                      staticClass:
                                                        "fas fa-image",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                media.mediaType === "File"
                                                  ? _c("svg", {
                                                      staticClass:
                                                        "fas fa-file",
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                media.mediaType === "Video"
                                                  ? _c("svg", {
                                                      staticClass:
                                                        "fas fa-video",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-lg-9" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(media.imageText)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row mb-4 conditions" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-lg-12" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.termsaccepted,
                                                  expression: "termsaccepted",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.termsaccepted
                                                )
                                                  ? _vm._i(
                                                      _vm.termsaccepted,
                                                      null
                                                    ) > -1
                                                  : _vm.termsaccepted,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.termsaccepted,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.termsaccepted =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.termsaccepted = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.termsaccepted = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _c("label", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.acceptTermsBtnLabel
                                                ),
                                              },
                                            }),
                                            _c("br"),
                                            _vm._v(" "),
                                            _vm.termsaccepted
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    on: { click: _vm.getZIP },
                                                  },
                                                  [
                                                    _c("svg", {
                                                      staticClass:
                                                        "fas fa-download",
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dll-btn-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.downloadsBtnLabel
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.termsaccepted
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    attrs: { disabled: "" },
                                                  },
                                                  [
                                                    _c("svg", {
                                                      staticClass:
                                                        "fas fa-download",
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dll-btn-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.downloadsBtnLabel
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.mediaList.mediaItems.length > _vm.showItems
                      ? _c(
                          "div",
                          { staticClass: "btn btn-primary btn-md show-more" },
                          [
                            _c("span", { on: { click: _vm.showMore } }, [
                              _vm._v(_vm._s(_vm.showMoreBtnLabel)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", [
      _vm._v("I accept the "),
      _c("a", { attrs: { href: "#" } }, [_vm._v("Terms and conditions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }