
    import { DictionaryRepository } from "repositories/DictionaryRepository";
    import Vue from "vue";
    import { Component, Prop } from "vue-property-decorator";
    import { DictionaryModel } from "../models/DictionaryModel";
    import Tab from "./Tab.vue";
    import Tabs from "./Tabs.vue";
    import TruckSelectorApplication from "./TruckSelectorApplication.vue";
    import TruckSelectorList from "./TruckSelectorList.vue";

    @Component({
        components: {
            Tab,
            Tabs,
            TruckSelectorApplication,
            TruckSelectorList,
        },
    })
    export default class TruckSelector extends Vue {
        @Prop() public nodeid!: number;
        @Prop() public language!: string;
        @Prop() public showprices!: boolean;

        public error: boolean = false;
        public dictionary: DictionaryModel | null = null;

        public async mounted() {
            const dictionaryRepo = new DictionaryRepository();
            try {
                this.error = false;
                this.dictionary = await dictionaryRepo.get("truckPageEntry", this.language);
            } catch (err) {
                this.error = true;
            }
        }
    }
