
    import Vue from "vue";
    import {Component, Prop} from "vue-property-decorator";
    import { ApplicationResponseModel } from "../models/ApplicationResponseModel";
    import { ApplicationRepository } from "../repositories/ApplicationRepository";

    @Component
    export default class TruckSelectorApplicationItem extends Vue {
        @Prop() public application!: ApplicationResponseModel;
    }
